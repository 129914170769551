var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": false,
      "is-centered": ""
    }
  }, [_c('c-modal-content', {
    ref: "content",
    attrs: {
      "max-width": ['90%', '36rem'],
      "max-height": "80vh"
    }
  }, [_c('c-modal-body', {
    attrs: {
      "p": "1rem"
    }
  }, [_c('vue-cropper', {
    ref: "cropper",
    attrs: {
      "src": _vm.image,
      "aspect-ratio": 1 / 1,
      "initial-aspect-ratio": 1 / 1,
      "container-style": {
        maxHeight: '60vh'
      }
    }
  }), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "gap": "20px",
      "w": "100%",
      "mt": "1rem",
      "mx": "auto",
      "align-items": "center",
      "justify-content": "center",
      "px": "10px"
    }
  }, [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          bg: '#D32737'
        }
      },
      expression: "{\n            ':hover': {\n              bg: '#D32737',\n            },\n          }"
    }],
    attrs: {
      "w": "100%",
      "max-w": "130px",
      "h": "34px",
      "bg": "#D32737",
      "color": "white",
      "border-radius": "16px",
      "px": "32px",
      "py": "7px",
      "disabled": _vm.isLoading
    },
    on: {
      "click": function click() {
        if (!_vm.isLoading) {
          _vm.$emit('close');
        }
      }
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "12px",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px'],
      "font-weight": ['500'],
      "align": "center"
    }
  }, [_vm._v(" Batal ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-cancel.svg'),
      "h": '24px',
      "w": '24px'
    }
  })], 1)], 1), _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          bg: '#008C81'
        },
        ':disabled': {
          opacity: '1 !important'
        }
      },
      expression: "{\n            ':hover': {\n              bg: '#008C81',\n            },\n            ':disabled': {\n              opacity: '1 !important',\n            },\n          }"
    }],
    attrs: {
      "w": "100%",
      "max-w": "130px",
      "h": "34px",
      "bg": "#008C81",
      "color": "white",
      "border-radius": "16px",
      "px": "14px",
      "py": "7px",
      "font-family": "Roboto",
      "font-size": ['14px'],
      "font-weight": ['500'],
      "is-loading": _vm.isLoading,
      "loading-text": "Uploading"
    },
    on: {
      "click": _vm.cropImage
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "12px",
      "align-items": "center",
      "justify-content": "center",
      "flex-grow": "1"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px'],
      "font-weight": ['500'],
      "align": "center"
    }
  }, [_vm._v(" Potong ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-confirm.svg'),
      "h": '24px',
      "w": '24px'
    }
  })], 1)], 1)], 1)], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }