var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm._l(_vm.data, function (item, index) {
    var _vm$invalid, _vm$invalid$$each$ind, _vm$invalid$$each$ind2, _vm$invalid2, _vm$invalid2$$each$in, _vm$invalid2$$each$in2, _vm$invalid3, _vm$invalid3$$each$in, _vm$invalid3$$each$in2;

    return _c('c-box', {
      key: 'specialization-' + index,
      attrs: {
        "position": "relative"
      }
    }, [_c('c-box', {
      attrs: {
        "color": (_vm$invalid = _vm.invalid) !== null && _vm$invalid !== void 0 && (_vm$invalid$$each$ind = _vm$invalid.$each[index]) !== null && _vm$invalid$$each$ind !== void 0 && (_vm$invalid$$each$ind2 = _vm$invalid$$each$ind.select) !== null && _vm$invalid$$each$ind2 !== void 0 && _vm$invalid$$each$ind2.$invalid ? 'danger.400' : _vm.isNutri ? 'primary.400' : '#555',
        "font-weight": _vm.isNutri ? '400' : '500',
        "font-family": "Roboto",
        "p": _vm.isNutri ? '0 12px 4px 8px' : '0 12px 4px 0',
        "font-size": _vm.isNutri ? ['14px', '16px'] : '14px'
      }
    }, [_vm._v(" Spesialisasi " + _vm._s(index + 1) + " ")]), _c('c-form-control', {
      attrs: {
        "mb": "12px",
        "is-invalid": (_vm$invalid2 = _vm.invalid) === null || _vm$invalid2 === void 0 ? void 0 : (_vm$invalid2$$each$in = _vm$invalid2.$each[index]) === null || _vm$invalid2$$each$in === void 0 ? void 0 : (_vm$invalid2$$each$in2 = _vm$invalid2$$each$in.select) === null || _vm$invalid2$$each$in2 === void 0 ? void 0 : _vm$invalid2$$each$in2.$invalid
      }
    }, [_c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "height": "62px",
        "placeholder": "Pilih Spesialisasi / Keahlian",
        "font-size": _vm.isNutri ? ['16px', '18px'] : '16px',
        "color": _vm.isNutri ? 'primary.400' : 'inherit',
        "font-weight": _vm.isNutri ? '500' : 'inherit',
        "border-radius": _vm.isNutri ? '0.5rem' : '0.25rem',
        "focus-border-color": _vm.isNutri ? 'primary.400' : 'inherit'
      },
      model: {
        value: item.select,
        callback: function callback($$v) {
          _vm.$set(item, "select", $$v);
        },
        expression: "item.select"
      }
    }, _vm._l(_vm.options, function (item_) {
      return _c('option', {
        key: item_.value,
        attrs: {
          "disabled": _vm.isAlreadyChecked(item_.value) && item_.value != _vm.otherValue
        },
        domProps: {
          "value": item_.value
        }
      }, [_vm._v(" " + _vm._s(item_.label) + " ")]);
    }), 0)], 1)], 1), item.select == _vm.otherValue ? _c('c-form-control', {
      attrs: {
        "is-required": "",
        "mb": "12px",
        "is-invalid": (_vm$invalid3 = _vm.invalid) === null || _vm$invalid3 === void 0 ? void 0 : (_vm$invalid3$$each$in = _vm$invalid3.$each[index]) === null || _vm$invalid3$$each$in === void 0 ? void 0 : (_vm$invalid3$$each$in2 = _vm$invalid3$$each$in.input) === null || _vm$invalid3$$each$in2 === void 0 ? void 0 : _vm$invalid3$$each$in2.$invalid
      }
    }, [_c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "font-size": _vm.isNutri ? ['16px', '18px'] : '16px',
        "color": _vm.isNutri ? 'primary.400' : 'inherit',
        "font-weight": _vm.isNutri ? '500' : 'inherit',
        "border-radius": _vm.isNutri ? '0.5rem' : '0.25rem',
        "focus-border-color": _vm.isNutri ? 'primary.400' : 'inherit',
        "error-border-color": "red.300",
        "placeholder": "Masukkan Lainnya",
        "height": "62px"
      },
      model: {
        value: item.input,
        callback: function callback($$v) {
          _vm.$set(item, "input", $$v);
        },
        expression: "item.input"
      }
    })], 1)], 1) : _vm._e(), _vm.data.length > 1 ? _c('c-box', {
      attrs: {
        "mb": "13px",
        "text-align": "right"
      }
    }, [_c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link",
        "font-size": "14px"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickRemove(index);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty",
        "mr": "4px",
        "width": "20px",
        "height": "20px"
      }
    }), _vm._v(" Hapus Spesialisasi " + _vm._s(index + 1) + " ")], 1)], 1) : _vm._e()], 1);
  }), _vm.data.length < 3 ? _c('c-button', {
    attrs: {
      "is-disabled": _vm.isEmpty,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "left-icon": "add",
      "mb": "25px",
      "mt": "12px"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add');
      }
    }
  }, [_vm._v(" Tambah Spesialisasi ")]) : _vm._e(), _c('ModalConfirm', {
    attrs: {
      "title": "Anda yakin ingin hapus spesialisasi ".concat(_vm.selectedIndex + 1, "?"),
      "image": require('@/assets/icon-confirm-delete.svg'),
      "is-open": _vm.isOpenConfirm,
      "is-loading": false,
      "submit-text": "Hapus"
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": _vm.onSubmitConfim
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }