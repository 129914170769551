import { render, staticRenderFns } from "./form-nutri.vue?vue&type=template&id=47b04300&"
import script from "./form-nutri.vue?vue&type=script&lang=js&"
export * from "./form-nutri.vue?vue&type=script&lang=js&"
import style0 from "./form-nutri.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CDivider: require('@chakra-ui/vue').CDivider, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CCheckbox: require('@chakra-ui/vue').CCheckbox})
