var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm._l(_vm.data, function (value, index) {
    return _c('c-box', {
      key: 'languages-' + index,
      attrs: {
        "position": "relative"
      }
    }, [_c('c-form-control', {
      attrs: {
        "mb": "12px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": _vm.isNutri ? ['14px', '16px'] : '14px',
        "color": _vm.isNutri ? 'primary.400' : '#555',
        "font-weight": _vm.isNutri ? '400' : '500',
        "font-family": "Roboto"
      }
    }, [_vm._v(" Bahasa " + _vm._s(index + 1) + " ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Bahasa",
        "height": "62px",
        "font-size": _vm.isNutri ? ['16px', '18px'] : '16px',
        "color": _vm.isNutri ? 'primary.400' : 'inherit',
        "font-weight": _vm.isNutri ? '500' : 'inherit',
        "border-radius": _vm.isNutri ? '0.5rem' : '0.25rem',
        "focus-border-color": _vm.isNutri ? 'primary.400' : 'inherit'
      },
      on: {
        "keydown": function keydown($event) {
          return _vm.handleInput($event);
        }
      },
      model: {
        value: _vm.data[index],
        callback: function callback($$v) {
          _vm.$set(_vm.data, index, $$v);
        },
        expression: "data[index]"
      }
    })], 1)], 1), _vm.data.length > 1 ? _c('c-box', {
      attrs: {
        "mb": "13px",
        "text-align": "right"
      }
    }, [_c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link",
        "font-size": "14px"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickRemove(index);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty",
        "mr": "4px",
        "width": "20px",
        "height": "20px"
      }
    }), _vm._v(" Hapus Bahasa " + _vm._s(index + 1) + " ")], 1)], 1) : _vm._e()], 1);
  }), _c('c-button', {
    attrs: {
      "is-disabled": _vm.isEmpty,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "left-icon": "add",
      "mb": "25px",
      "mt": "12px"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add');
      }
    }
  }, [_vm._v(" Tambah Bahasa ")]), _c('ModalConfirm', {
    attrs: {
      "title": "Anda yakin ingin hapus bahasa ".concat(_vm.selectedIndex + 1, "?"),
      "image": require('@/assets/icon-confirm-delete.svg'),
      "is-open": _vm.isOpenConfirm,
      "is-loading": false,
      "submit-text": "Hapus"
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": _vm.onSubmitConfim
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }