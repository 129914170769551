var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('h1', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "d": ['none', 'block'],
      "font-weight": "bold",
      "font-size": "18px",
      "color": "#111111",
      "mb": "30px"
    }
  }, [_vm._v(" Data Diri ")]), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": ['70px', 0]
    }
  }, [_c('BaseInputText', {
    attrs: {
      "label": "ID Nutritionist",
      "placeholder": "Masukkan ID Nutritionist",
      "full-width": "",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.value.id),
      "invalid-text": _vm.parseErrors('Kode', _vm.$v.value.id)
    },
    on: {
      "blur": _vm.$v.value.id.$touch
    },
    model: {
      value: _vm.value.id,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "id", $$v);
      },
      expression: "value.id"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Email",
      "placeholder": "Masukkan Email",
      "full-width": "",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.value.email),
      "invalid-text": _vm.parseErrors('Email', _vm.$v.value.email)
    },
    on: {
      "blur": _vm.$v.value.email.$touch
    },
    model: {
      value: _vm.value.email,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "email", $$v);
      },
      expression: "value.email"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Nomor STR",
      "placeholder": "Masukkan STR",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.value.str),
      "invalid-text": _vm.parseErrors('Nomor STR', _vm.$v.value.str)
    },
    on: {
      "blur": _vm.$v.value.str.$touch
    },
    model: {
      value: _vm.value.str,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "str", $$v);
      },
      expression: "value.str"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Nama Depan",
      "placeholder": "Masukkan Nama Depan",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.value.firstName),
      "invalid-text": _vm.parseErrors('Nama depan', _vm.$v.value.firstName)
    },
    on: {
      "blur": _vm.$v.value.firstName.$touch
    },
    model: {
      value: _vm.value.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "firstName", $$v);
      },
      expression: "value.firstName"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Nama Belakang",
      "placeholder": "Masukkan Nama Belakang",
      "full-width": ""
    },
    model: {
      value: _vm.value.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "lastName", $$v);
      },
      expression: "value.lastName"
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Spesialisasi / Keahlian ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormSpecialization', {
    attrs: {
      "data": _vm.value.specialization,
      "options": _vm.SPECIALIZATION,
      "is-empty": _vm.isEmptySpecialization,
      "invalid": _vm.$v.value.specialization,
      "other-value": _vm.otherOptionValue,
      "type": "nutri"
    },
    on: {
      "add": _vm.addSpecialization,
      "remove": _vm.removeSpecialization
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Bahasa yang dikuasai ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormLanguages', {
    attrs: {
      "data": _vm.value.languages,
      "is-empty": _vm.isEmptyLanguages,
      "type": "nutri"
    },
    on: {
      "add": _vm.addLanguages,
      "remove": _vm.removeLanguages
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Usia Klien ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormClientAgeHandled', {
    attrs: {
      "data": _vm.value.clientAgeHandled,
      "is-empty": _vm.isEmptyClientAgeHandled,
      "type": "nutri"
    },
    on: {
      "add": _vm.addClientAgeHandled,
      "remove": _vm.removeClientAgeHandled
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Masalah yang dikuasai ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('FormProblemHandled', {
    attrs: {
      "data": _vm.value.problemHandled,
      "is-empty": _vm.isEmptyProblemHandled,
      "type": "nutri"
    },
    on: {
      "add": _vm.addProblemHandled,
      "remove": _vm.removeProblemHandled
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Pendidikan ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('TestFieldArray', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var push = _ref.push;
        return [_c('c-box', [_vm._l(_vm.value.education, function (v, i) {
          var _vm$value, _vm$value$education;

          return _c('c-box', {
            key: i,
            attrs: {
              "margin-top": "16px",
              "_first": {
                marginTop: '0px'
              }
            }
          }, [_c('BaseInputSelect', {
            attrs: {
              "label": "Degree",
              "options": _vm.degre,
              "placeholder": "Masukkan Level Degre",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.value.education.$each[i].level),
              "invalid-text": _vm.parseErrors('Degree', _vm.$v.value.education.$each[i].level),
              "full-width": ""
            },
            on: {
              "blur": _vm.$v.value.education.$each[i].level.$touch
            },
            model: {
              value: v.level,
              callback: function callback($$v) {
                _vm.$set(v, "level", $$v);
              },
              expression: "v.level"
            }
          }), _c('BaseInputText', {
            attrs: {
              "label": "Gelar",
              "placeholder": "Masukkan Gelar",
              "full-width": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.value.education.$each[i].title),
              "invalid-text": _vm.parseErrors('Gelar', _vm.$v.value.education.$each[i].title)
            },
            on: {
              "blur": _vm.$v.value.education.$each[i].title.$touch
            },
            model: {
              value: v.title,
              callback: function callback($$v) {
                _vm.$set(v, "title", $$v);
              },
              expression: "v.title"
            }
          }), _c('BaseInputAutocomplete', {
            attrs: {
              "label": "Universitas",
              "placeholder": "Masukkan Universitas",
              "full-width": "",
              "is-required": "",
              "options": _vm.dataUniversities,
              "is-invalid": _vm.isInvalidField(_vm.$v.value.education.$each[i].university),
              "invalid-text": _vm.parseErrors('Universitas', _vm.$v.value.education.$each[i].university)
            },
            on: {
              "blur": _vm.$v.value.education.$each[i].university.$touch
            },
            model: {
              value: v.university,
              callback: function callback($$v) {
                _vm.$set(v, "university", $$v);
              },
              expression: "v.university"
            }
          }), v.university === '0' ? _c('BaseInputText', {
            attrs: {
              "label": "",
              "placeholder": "Masukkan Universitas Lainnya",
              "full-width": "",
              "is-required": null,
              "is-invalid": _vm.isInvalidField(_vm.$v.value.education.$each[i].otherUniversity),
              "invalid-text": _vm.parseErrors('Gelar', _vm.$v.value.education.$each[i].otherUniversity)
            },
            on: {
              "blur": _vm.$v.value.education.$each[i].otherUniversity.$touch
            },
            model: {
              value: v.otherUniversity,
              callback: function callback($$v) {
                _vm.$set(v, "otherUniversity", $$v);
              },
              expression: "v.otherUniversity"
            }
          }) : _vm._e(), ((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$education = _vm$value.education) === null || _vm$value$education === void 0 ? void 0 : _vm$value$education.length) > 1 ? _c('c-flex', {
            attrs: {
              "justify-content": "end"
            }
          }, [_c('c-button', {
            attrs: {
              "ml": "18px",
              "min-width": "auto",
              "variant-color": "red",
              "variant": "link",
              "font-size": "14px"
            },
            on: {
              "click": function click() {
                _vm.selectedIndexEducation = i;
                _vm.isOpenConfirmEducation = true;
              }
            }
          }, [_c('c-image', {
            attrs: {
              "src": require('@/assets/icon-trash-line.svg'),
              "alt": "empty",
              "mr": "4px",
              "width": "20px",
              "height": "20px"
            }
          }), _vm._v(" Hapus Pendidikan " + _vm._s(i + 1) + " ")], 1)], 1) : _vm._e()], 1);
        }), _c('BaseButton', {
          attrs: {
            "margin-top": "28px",
            "disabled": _vm.isEmptyEducation,
            "size": "medium",
            "border-radius": "1000px",
            "width": "100%",
            "variant": "outlined",
            "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
            "left-svg-icon-color": "#008C81"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return push(_vm.value.education, {
                position: null,
                companyName: null,
                endYear: null,
                startYear: null,
                isCurrentlyWork: false
              });
            }
          }
        }, [_vm._v(" Tambah Pendidikan ")])], 2)];
      }
    }])
  }), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#008C81",
      "margin-top": "24px"
    }
  }, [_vm._v(" Pengalaman Bekerja ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('TestFieldArray', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var push = _ref2.push;
        return [_vm._l(_vm.value.workExperiences, function (v, i) {
          return _c('c-box', {
            key: i,
            attrs: {
              "margin-top": "16px",
              "_first": {
                marginTop: '0px'
              }
            }
          }, [_c('BaseInputText', {
            attrs: {
              "label": "Posisi Bekerja ".concat(i + 1),
              "placeholder": "Masukkan Posisi Bekerja",
              "full-width": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.value.workExperiences.$each[i].position),
              "invalid-text": _vm.parseErrors('Spesialisasi / keahlian', _vm.$v.value.workExperiences.$each[i].position)
            },
            on: {
              "blur": _vm.$v.value.workExperiences.$each[i].position.$touch
            },
            model: {
              value: v.position,
              callback: function callback($$v) {
                _vm.$set(v, "position", $$v);
              },
              expression: "v.position"
            }
          }), _c('BaseInputText', {
            attrs: {
              "label": "Nama Perusahaan",
              "placeholder": "Masukkan Pengalaman Bekerja",
              "full-width": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.value.workExperiences.$each[i].companyName),
              "invalid-text": _vm.parseErrors('Nama perusahaan', _vm.$v.value.workExperiences.$each[i].companyName)
            },
            on: {
              "blur": _vm.$v.value.workExperiences.$each[i].companyName.$touch
            },
            model: {
              value: v.companyName,
              callback: function callback($$v) {
                _vm.$set(v, "companyName", $$v);
              },
              expression: "v.companyName"
            }
          }), _c('c-flex', {
            attrs: {
              "gap": "32px"
            }
          }, [_c('BaseInputDate', {
            attrs: {
              "label": "Tahun Masuk",
              "placeholder": "Masukkan Tahun Masuk",
              "full-width": "",
              "type": "year",
              "format": "",
              "value-type": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.value.workExperiences.$each[i].startYear),
              "invalid-text": _vm.parseErrors('Start date', _vm.$v.value.workExperiences.$each[i].startYear)
            },
            on: {
              "blur": _vm.$v.value.workExperiences.$each[i].startYear.$touch
            },
            model: {
              value: v.startYear,
              callback: function callback($$v) {
                _vm.$set(v, "startYear", $$v);
              },
              expression: "v.startYear"
            }
          }), _c('BaseInputDate', {
            attrs: {
              "label": "Tahun Keluar",
              "placeholder": "Masukkan Tahun Keluar",
              "full-width": "",
              "type": "year",
              "format": "",
              "value-type": "",
              "is-required": "",
              "is-disabled": v.isCurrentlyWork,
              "is-invalid": _vm.isInvalidField(_vm.$v.value.workExperiences.$each[i].endYear),
              "invalid-text": _vm.parseErrors('Start date', _vm.$v.value.workExperiences.$each[i].endYear)
            },
            on: {
              "blur": _vm.$v.value.workExperiences.$each[i].endYear.$touch
            },
            model: {
              value: v.endYear,
              callback: function callback($$v) {
                _vm.$set(v, "endYear", $$v);
              },
              expression: "v.endYear"
            }
          })], 1), _c('c-flex', {
            attrs: {
              "gap": "32px",
              "px": "16px",
              "justify-content": "space-between"
            }
          }, [_c('c-checkbox', {
            attrs: {
              "size": "md",
              "variant-color": "primary"
            },
            model: {
              value: v.isCurrentlyWork,
              callback: function callback($$v) {
                _vm.$set(v, "isCurrentlyWork", $$v);
              },
              expression: "v.isCurrentlyWork"
            }
          }, [_c('c-text', {
            attrs: {
              "font-size": "14px",
              "color": "#888"
            }
          }, [_vm._v(" Saya masih bekerja di sini ")])], 1), _vm.value.workExperiences.length > 1 ? _c('c-button', {
            attrs: {
              "ml": "18px",
              "min-width": "auto",
              "variant-color": "red",
              "variant": "link",
              "font-size": "14px"
            },
            on: {
              "click": function click() {
                _vm.selectedIndexExprerience = i;
                _vm.isOpenConfirmExprerience = true;
              }
            }
          }, [_c('c-image', {
            attrs: {
              "src": require('@/assets/icon-trash-line.svg'),
              "alt": "empty",
              "mr": "4px",
              "width": "20px",
              "height": "20px"
            }
          }), _vm._v(" Hapus Pengalaman " + _vm._s(i + 1) + " ")], 1) : _vm._e()], 1)], 1);
        }), _c('BaseButton', {
          attrs: {
            "mt": "28px",
            "disabled": _vm.isEmptyworkExperiences,
            "size": "medium",
            "border-radius": "1000px",
            "width": "100%",
            "variant": "outlined",
            "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
            "left-svg-icon-color": "#008C81"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return push(_vm.value.workExperiences, {
                position: null,
                companyName: null,
                endYear: null,
                startYear: null,
                isCurrentlyWork: false
              });
            }
          }
        }, [_vm._v(" Tambah Pengalaman Kerja ")])];
      }
    }])
  }), _c('c-flex', {
    attrs: {
      "mt": "28px",
      "justify-content": "space-between",
      "gap": "20px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "medium",
      "border-radius": "1000px",
      "width": "100%",
      "variant": "outlined"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" Batal ")]), _c('BaseButton', {
    attrs: {
      "size": "medium",
      "border-radius": "1000px",
      "width": "100%",
      "disabled": _vm.isDisabledSubmit
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Simpan ")])], 1)], 1), _c('ModalConfirm', {
    attrs: {
      "title": "Anda yakin ingin hapus pengalaman bekerja ".concat(_vm.selectedIndexExprerience + 1, "?"),
      "image": require('@/assets/icon-confirm-delete.svg'),
      "is-open": _vm.isOpenConfirmExprerience,
      "is-loading": false,
      "submit-text": "Hapus"
    },
    on: {
      "close": function close($event) {
        _vm.isOpenConfirmExprerience = false;
      },
      "submit": function submit() {
        _vm.value.workExperiences.splice(_vm.selectedIndexExprerience, 1);

        _vm.isOpenConfirmExprerience = false;
      }
    }
  }), _c('ModalConfirm', {
    attrs: {
      "title": "Anda yakin ingin hapus pendidikan ".concat(_vm.selectedIndexEducation + 1, "?"),
      "image": require('@/assets/icon-confirm-delete.svg'),
      "is-open": _vm.isOpenConfirmEducation,
      "is-loading": false,
      "submit-text": "Hapus"
    },
    on: {
      "close": function close($event) {
        _vm.isOpenConfirmEducation = false;
      },
      "submit": function submit() {
        _vm.value.education.splice(_vm.selectedIndexEducation, 1);

        _vm.isOpenConfirmEducation = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }