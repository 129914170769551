<template>
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="true"
    is-centered
    size="2xl"
    :on-close="close"
  >
    <c-modal-content
      ref="content"
      class="modalss"
      w="100%"
      :max-w="maxWidth"
    >
      <c-modal-body
        p="0"
        d="flex"
        flex-direction="column"
        justify-content="center"
        align-items="center"
      >
        <CImage
          :src="image"
          alt="success"
          pos="relative"
          z-index="1"
          mx="auto"
          mb="20px"
          h="150px"
        />
        <c-text
          font-size="20px"
          font-weight="700"
          font-family="Roboto"
          align="center"
          color="#008C81"
          pb="20px"
        >
          {{ title }}
        </c-text>
        <c-text
          v-if="note"
          :font-size="['12px', '16px']"
          font-weight="400"
          font-family="Roboto"
          align="center"
          color="#555555"
          pb="20px"
        >
          {{ note }}
        </c-text>
        <c-flex
          flex-direction="row"
          gap="20px"
          w="100%"
          mx="auto"
          justify-content="center"
        >
          <c-button
            v-chakra="{
              ':hover': {
                bg: '#008C81',
              },
              ':disabled': {
                opacity: '1 !important',
              },
            }"
            min-width="130px"
            :w="['fit-content']"
            h="34px"
            bg="#008C81"
            color="white"
            :border-radius="['16px', '32px']"
            :px="['10px', '50px']"
            py="7px"
            font-family="Roboto"
            :font-size="['14px']"
            :font-weight="['500']"
            @click="close"
          >
            <c-flex
              gap="12px"
              align-items="center"
              justify-content="center"
              flex-grow="1"
            >
              <c-text
                font-family="Roboto"
                :font-size="['14px', '16px']"
                :font-weight="['500']"
                align="center"
              > 
                {{ buttonText }}
              </c-text>
              <c-image
                :src="require('@/assets/icons/icon-confirm.svg')"
                :h="'24px'"
                :w="'24px'"
              />
            </c-flex>
          </c-button>
        </c-flex>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>

export default {
  name: 'ModalSuccess',
  props: {
    isOpen: {
      type: Boolean,
    },
    maxWidth: {
      type: String,
      default: '400px',
    },
    image: {
      type: String,
      default: 'https://ik.imagekit.io/dietela/pwa_webp/popup/popup_changepassword_success.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1677042871711',
    },
    title: {
      type: String,
      default: 'Data berhasil diubah!',
    },
    note: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Oke',  
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style scoped>
.modalss::v-deep section {
  border-radius: 16px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 20px;
}
</style>
