<template>
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="false"
    is-centered
  >
    <c-modal-content
      ref="content"
      :max-width="['90%', '36rem']"
      max-height="80vh"
    >
      <c-modal-body p="1rem">
        <vue-cropper
          ref="cropper"
          :src="image"
          :aspect-ratio="1/1"
          :initial-aspect-ratio="1/1"
          :container-style="{
            maxHeight: '60vh',
          }"
        />
        <c-flex
          flex-direction="row"
          gap="20px"
          w="100%"
          mt="1rem"
          mx="auto"
          align-items="center"
          justify-content="center"
          px="10px"
        >
          <c-button
            v-chakra="{
              ':hover': {
                bg: '#D32737',
              },
            }"
            w="100%"
            max-w="130px"
            h="34px"
            bg="#D32737"
            color="white"
            border-radius="16px"
            px="32px"
            py="7px"
            :disabled="isLoading"
            @click="() => {
              if (!isLoading) {
                $emit('close')
              }
            }"
          >
            <c-flex
              gap="12px"
              align-items="center"
              justify-content="center"
            >
              <c-text
                font-family="Roboto"
                :font-size="['14px']"
                :font-weight="['500']"
                align="center"
              >
                Batal
              </c-text>
              <c-image
                :src="require('@/assets/icons/icon-cancel.svg')"
                :h="'24px'"
                :w="'24px'"
              />
            </c-flex>
          </c-button>
          <c-button
            v-chakra="{
              ':hover': {
                bg: '#008C81',
              },
              ':disabled': {
                opacity: '1 !important',
              },
            }"
            w="100%"
            max-w="130px"
            h="34px"
            bg="#008C81"
            color="white"
            border-radius="16px"
            px="14px"
            py="7px"
            font-family="Roboto"
            :font-size="['14px']"
            :font-weight="['500']"
            :is-loading="isLoading"
            loading-text="Uploading"
            @click="cropImage"
          >
            <c-flex
              gap="12px"
              align-items="center"
              justify-content="center"
              flex-grow="1"
            >
              <c-text
                font-family="Roboto"
                :font-size="['14px']"
                :font-weight="['500']"
                align="center"
              >
                Potong
              </c-text>
              <c-image
                :src="require('@/assets/icons/icon-confirm.svg')"
                :h="'24px'"
                :w="'24px'"
              />
            </c-flex>
          </c-button>
        </c-flex>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'ModalCropper',
  components: {
    VueCropper,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cropImage() {
      const canvas = this.$refs.cropper.getCroppedCanvas()
      canvas.toBlob(blob => {
        blob.name = this.fileName
        blob.lastModifiedDate = new Date()
        const image = new File([blob], blob.name, { type: 'image/jpg' })
        this.$emit('submit', image)
      })
    }, 
  },
}
</script>
