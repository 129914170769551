<template>
  <c-box flex-grow="1">
    <h1
      v-chakra
      :d="['none', 'block']"
      font-weight="bold"
      font-size="18px"
      color="#111111"
      mb="30px"
    >
      Data Diri
    </h1>
    <form
      v-chakra
      :mt="['70px', 0]"
    >
      <BaseInputText
        v-model="value.id"
        label="ID Nutritionist"
        placeholder="Masukkan ID Nutritionist"
        full-width
        is-required
        is-disabled
        :is-invalid="isInvalidField($v.value.id)"
        :invalid-text="parseErrors('Kode', $v.value.id)"
        @blur="$v.value.id.$touch"
      />

      <BaseInputText
        v-model="value.email"
        label="Email"
        placeholder="Masukkan Email"
        full-width
        is-required
        is-disabled
        :is-invalid="isInvalidField($v.value.email)"
        :invalid-text="parseErrors('Email', $v.value.email)"
        @blur="$v.value.email.$touch"
      />

      <BaseInputText
        v-model="value.str"
        label="Nomor STR"
        placeholder="Masukkan STR"
        full-width
        is-required
        :is-invalid="isInvalidField($v.value.str)"
        :invalid-text="parseErrors('Nomor STR', $v.value.str)"
        @blur="$v.value.str.$touch"
      />

      <BaseInputText
        v-model="value.firstName"
        label="Nama Depan"
        placeholder="Masukkan Nama Depan"
        full-width
        is-required
        :is-invalid="isInvalidField($v.value.firstName)"
        :invalid-text="parseErrors('Nama depan', $v.value.firstName)"
        @blur="$v.value.firstName.$touch"
      />

      <BaseInputText
        v-model="value.lastName"
        label="Nama Belakang"
        placeholder="Masukkan Nama Belakang"
        full-width
      />

      <c-heading
        as="h4"
        font-family="Roboto"
        font-weight="bold"
        font-size="18px"
        line-height="27px"
        color="#008C81"
      >
        Spesialisasi / Keahlian
      </c-heading>
      <c-divider
        mt="10px"
        mb="22px"
        color="#888"
      />
      <FormSpecialization
        :data="value.specialization"
        :options="SPECIALIZATION"
        :is-empty="isEmptySpecialization"
        :invalid="$v.value.specialization"
        :other-value="otherOptionValue"
        type="nutri"
        @add="addSpecialization"
        @remove="removeSpecialization"
      />

      <c-heading
        as="h4"
        font-family="Roboto"
        font-weight="bold"
        font-size="18px"
        line-height="27px"
        color="#008C81"
      >
        Bahasa yang dikuasai
      </c-heading>
      <c-divider
        mt="10px"
        mb="22px"
        color="#888"
      />
      <FormLanguages
        :data="value.languages"
        :is-empty="isEmptyLanguages"
        type="nutri"
        @add="addLanguages"
        @remove="removeLanguages"
      />

      <c-heading
        as="h4"
        font-family="Roboto"
        font-weight="bold"
        font-size="18px"
        line-height="27px"
        color="#008C81"
      >
        Usia Klien
      </c-heading>
      <c-divider
        mt="10px"
        mb="22px"
        color="#888"
      />
      <FormClientAgeHandled
        :data="value.clientAgeHandled"
        :is-empty="isEmptyClientAgeHandled"
        type="nutri"
        @add="addClientAgeHandled"
        @remove="removeClientAgeHandled"
      />

      <c-heading
        as="h4"
        font-family="Roboto"
        font-weight="bold"
        font-size="18px"
        line-height="27px"
        color="#008C81"
      >
        Masalah yang dikuasai
      </c-heading>
      <c-divider
        mt="10px"
        mb="22px"
        color="#888"
      />
      <FormProblemHandled
        :data="value.problemHandled"
        :is-empty="isEmptyProblemHandled"
        type="nutri"
        @add="addProblemHandled"
        @remove="removeProblemHandled"
      />

      <c-heading
        as="h4"
        font-family="Roboto"
        font-weight="bold"
        font-size="18px"
        line-height="27px"
        color="#008C81"
      >
        Pendidikan
      </c-heading>
      <c-divider
        mt="10px"
        mb="22px"
        color="#888"
      />

      <TestFieldArray v-slot="{ push }">
        <c-box>
          <c-box
            v-for="(v, i) in value.education"
            :key="i"
            margin-top="16px"
            :_first="{ marginTop: '0px' }"
          >
            <BaseInputSelect
              v-model="v.level"
              label="Degree"
              :options="degre"
              placeholder="Masukkan Level Degre"
              is-required
              :is-invalid="isInvalidField($v.value.education.$each[i].level)"
              :invalid-text="parseErrors('Degree', $v.value.education.$each[i].level)"
              full-width
              @blur="$v.value.education.$each[i].level.$touch"
            />
            <BaseInputText
              v-model="v.title"
              label="Gelar"
              placeholder="Masukkan Gelar"
              full-width
              is-required
              :is-invalid="isInvalidField($v.value.education.$each[i].title)"
              :invalid-text="parseErrors('Gelar', $v.value.education.$each[i].title)"
              @blur="$v.value.education.$each[i].title.$touch"
            />
            <BaseInputAutocomplete
              v-model="v.university"
              label="Universitas"
              placeholder="Masukkan Universitas"
              full-width
              is-required
              :options="dataUniversities"
              :is-invalid="isInvalidField($v.value.education.$each[i].university)"
              :invalid-text="parseErrors('Universitas', $v.value.education.$each[i].university)"
              @blur="$v.value.education.$each[i].university.$touch"
            />
            <BaseInputText
              v-if="v.university === '0'"
              v-model="v.otherUniversity"
              label=""
              placeholder="Masukkan Universitas Lainnya"
              full-width
              :is-required="null"
              :is-invalid="isInvalidField($v.value.education.$each[i].otherUniversity)"
              :invalid-text="parseErrors('Gelar', $v.value.education.$each[i].otherUniversity)"
              @blur="$v.value.education.$each[i].otherUniversity.$touch"
            />
            <c-flex
              v-if="value?.education?.length > 1"
              justify-content="end"
            >
              <c-button
                ml="18px"
                min-width="auto"
                variant-color="red"
                variant="link"
                font-size="14px"
                @click="() => {
                  selectedIndexEducation = i
                  isOpenConfirmEducation = true
                }"
              >
                <c-image
                  :src="require('@/assets/icon-trash-line.svg')"
                  alt="empty"
                  mr="4px"
                  width="20px"
                  height="20px"
                />
                Hapus Pendidikan {{ i+1 }}
              </c-button>
            </c-flex>
          </c-box>
          <BaseButton
            margin-top="28px"
            :disabled="isEmptyEducation"
            size="medium"
            border-radius="1000px"
            width="100%"
            variant="outlined"
            :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
            left-svg-icon-color="#008C81"
            @click.prevent="push(value.education, {
              position: null,
              companyName: null,
              endYear: null,
              startYear: null,
              isCurrentlyWork: false,
            })"
          >
            Tambah Pendidikan
          </BaseButton>
        </c-box>
      </TestFieldArray>

      <c-heading
        as="h4"
        font-family="Roboto"
        font-weight="bold"
        font-size="18px"
        line-height="27px"
        color="#008C81"
        margin-top="24px"
      >
        Pengalaman Bekerja
      </c-heading>
      <c-divider
        mt="10px"
        mb="22px"
        color="#888"
      />

      <TestFieldArray v-slot="{ push }">
        <c-box
          v-for="(v, i) in value.workExperiences"
          :key="i"
          margin-top="16px"
          :_first="{ marginTop: '0px' }"
        >
          <BaseInputText
            v-model="v.position"
            :label="`Posisi Bekerja ${i+1}`"
            placeholder="Masukkan Posisi Bekerja"
            full-width
            is-required
            :is-invalid="isInvalidField($v.value.workExperiences.$each[i].position)"
            :invalid-text="parseErrors('Spesialisasi / keahlian', $v.value.workExperiences.$each[i].position)"
            @blur="$v.value.workExperiences.$each[i].position.$touch"
          />
          <BaseInputText
            v-model="v.companyName"
            label="Nama Perusahaan"
            placeholder="Masukkan Pengalaman Bekerja"
            full-width
            is-required
            :is-invalid="isInvalidField($v.value.workExperiences.$each[i].companyName)"
            :invalid-text="parseErrors('Nama perusahaan', $v.value.workExperiences.$each[i].companyName)"
            @blur="$v.value.workExperiences.$each[i].companyName.$touch"
          />
          <c-flex gap="32px">
            <BaseInputDate
              v-model="v.startYear"
              label="Tahun Masuk"
              placeholder="Masukkan Tahun Masuk"
              full-width
              type="year"
              format=""
              value-type=""
              is-required
              :is-invalid="isInvalidField($v.value.workExperiences.$each[i].startYear)"
              :invalid-text="parseErrors('Start date', $v.value.workExperiences.$each[i].startYear)"
              @blur="$v.value.workExperiences.$each[i].startYear.$touch"
            />
            <BaseInputDate
              v-model="v.endYear"
              label="Tahun Keluar"
              placeholder="Masukkan Tahun Keluar"
              full-width
              type="year"
              format=""
              value-type=""
              is-required
              :is-disabled="v.isCurrentlyWork"
              :is-invalid="isInvalidField($v.value.workExperiences.$each[i].endYear)"
              :invalid-text="parseErrors('Start date', $v.value.workExperiences.$each[i].endYear)"
              @blur="$v.value.workExperiences.$each[i].endYear.$touch"
            />
          </c-flex>
          <c-flex
            gap="32px"
            px="16px"
            justify-content="space-between"
          >
            <c-checkbox
              v-model="v.isCurrentlyWork"
              size="md"
              variant-color="primary"
            >
              <c-text
                font-size="14px"
                color="#888"
              >
                Saya masih bekerja di sini
              </c-text>
            </c-checkbox>
            <c-button
              v-if="value.workExperiences.length > 1"
              ml="18px"
              min-width="auto"
              variant-color="red"
              variant="link"
              font-size="14px"
              @click="() => {
                selectedIndexExprerience = i
                isOpenConfirmExprerience = true
              }"
            >
              <c-image
                :src="require('@/assets/icon-trash-line.svg')"
                alt="empty"
                mr="4px"
                width="20px"
                height="20px"
              />
              Hapus Pengalaman {{ i+1 }}
            </c-button>
          </c-flex>
        </c-box>
        <BaseButton
          mt="28px"
          :disabled="isEmptyworkExperiences"
          size="medium"
          border-radius="1000px"
          width="100%"
          variant="outlined"
          :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="#008C81"
          @click.prevent="push(value.workExperiences, {
            position: null,
            companyName: null,
            endYear: null,
            startYear: null,
            isCurrentlyWork: false,
          })"
        >
          Tambah Pengalaman Kerja
        </BaseButton>
      </TestFieldArray>

      <c-flex
        mt="28px"
        justify-content="space-between"
        gap="20px"
      >
        <BaseButton
          size="medium"
          border-radius="1000px"
          width="100%"
          variant="outlined"
          @click="$router.back()"
        >
          Batal
        </BaseButton>
        <BaseButton
          size="medium"
          border-radius="1000px"
          width="100%"
          :disabled="isDisabledSubmit"
          @click="onSubmit"
        >
          Simpan
        </BaseButton>
      </c-flex>
    </form>

    <ModalConfirm
      :title="`Anda yakin ingin hapus pengalaman bekerja ${selectedIndexExprerience+1}?`"
      :image="require('@/assets/icon-confirm-delete.svg')"
      :is-open="isOpenConfirmExprerience"
      :is-loading="false"
      submit-text="Hapus"
      @close="isOpenConfirmExprerience = false"
      @submit="() => {
        value.workExperiences.splice(selectedIndexExprerience, 1)
        isOpenConfirmExprerience = false
      }"
    />

    <ModalConfirm
      :title="`Anda yakin ingin hapus pendidikan ${selectedIndexEducation+1}?`"
      :image="require('@/assets/icon-confirm-delete.svg')"
      :is-open="isOpenConfirmEducation"
      :is-loading="false"
      submit-text="Hapus"
      @close="isOpenConfirmEducation = false"
      @submit="() => {
        value.education.splice(selectedIndexEducation, 1)
        isOpenConfirmEducation = false
      }"
    />
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { email, required, requiredIf } from 'vuelidate/lib/validators'
import BaseInputText from '@/components/elements/base-input-text.vue'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import BaseButton from '@/components/elements/base-button.vue'
import TestFieldArray from '@/views/profile/detail/test-field-array.vue'
import BaseInputDate from '@/components/elements/base-input-date.vue'
import ModalConfirm from '@/components/widgets/modal-confirm.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import BaseInputAutocomplete from '@/components/elements/base-input-autocomplete.vue'
import FormSpecialization from '@/views/admin/nutritionists/_widgets/form-specialization.vue'
import FormLanguages from '@/views/admin/nutritionists/_widgets/form-languages.vue'
import FormClientAgeHandled from '@/views/admin/nutritionists/_widgets/form-client-age-handled.vue'
import FormProblemHandled from '@/views/admin/nutritionists/_widgets/form-problem-handled.vue'
import { SPECIALIZATION } from '@/constants/generals.js'

export default {
  name: 'ProfileDetailForm',
  components: {
    BaseInputAutocomplete,
    BaseInputSelect,
    ModalConfirm,
    BaseInputDate,
    TestFieldArray,
    BaseButton,
    BaseInputText,
    FormSpecialization,
    FormLanguages,
    FormClientAgeHandled,
    FormProblemHandled,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        id: null,
        email: null,
        str: null,
        firstName: null,
        lastName: null,
        education: [
          {
            level: null,
            title: null,
            university: null,
            otherUniversity: null,
          },
        ],
        specialization: [
          {
            select: '',
            input: '',
            isOther: 'Lainnya',
          },
        ],
        languages: [''],
        clientAgeHandled: [''],
        workExperiences: [
          {
            position: null,
            companyName: null,
            startYear: null,
            endYear: null,
            isCurrentlyWork: false,
          },
        ],
      }),
    },
  },
  data() {
    return {
      isOpenConfirmExprerience: false,
      selectedIndexExprerience: null,
      isOpenConfirmEducation: false,
      selectedIndexEducation: null,
      testValue: [
        {
          id: 1,
          name: 'test 1',
        },
        {
          id: 2,
          name: 'test 2',
        },
      ],
      degre: [
        { value: 'd1', label: 'D1' },
        { value: 'd3', label: 'D3' },
        { value: 'd4', label: 'D4' },
        { value: 's1', label: 'S1' },
        { value: 's2', label: 'S2' },
        { value: 's3', label: 'S3' },
        { value: 'pendidikan profesi dietisien', label: 'Pendidikan Profesi Dietisien' },
      ],
      SPECIALIZATION,
    }
  },
  computed: {
    ...mapGetters({
      universities: 'general/universities',
    }),
    dataUniversities() {
      return [
        { id: '0', name: 'Masukkan Lainnya' },
        ...this.universities.map((dt) => {
          dt.id = dt.id.toString()
          return dt
        }),
      ]
    },
    isDisabledSubmit() {
      return this.$v.$invalid
    },
    otherOptionValue() {
      return this.SPECIALIZATION.find((it) => it?.isOther)?.value
    },
    isEmptySpecialization() {
      if (this.value?.specialization) {
        return this.value?.specialization?.some((it) => {
          if (!it.select) {
            return true
          } else {
            return it.select == this.otherOptionValue ? !it.input : false
          }
        })
      }
      return false
    },
    isEmptyLanguages() {
      if (this.value?.languages) {
        return this.value.languages.includes('')
      }
      return false
    },
    isEmptyClientAgeHandled() {
      if (this.value?.clientAgeHandled) {
        return this.value.clientAgeHandled.includes('')
      }
      return false
    },
    isEmptyProblemHandled() {
      if (this.value?.problemHandled) {
        return this.value.problemHandled.includes('')
      }
      return false
    },
    isEmptyworkExperiences() {
      if (this.value?.workExperiences) {
        let emptyObj = this.value.workExperiences.findIndex(
          (dt) => !dt.position || !dt.companyName || (!dt.endYear && !dt?.isCurrentlyWork) || !dt.startYear,
        )
        if (emptyObj > -1) return true
      }
      return false
    },
    isEmptyEducation() {
      if (this.value?.education) {
        let emptyObj = this.value.education.findIndex(
          (dt) => !dt.level || !dt.title || !dt.university,
        )
        if (emptyObj > -1) return true
      }
      return false
    },
  },
  validations: {
    value: {
      id: { required },
      email: { required, email },
      str: { required },
      firstName: { required },
      specialization: { 
        required,
        $each: { 
          select: { required },
          input: { required: requiredIf((model) => model.select == model.isOther) },
        },
      },
      education: {
        required,
        $each: {
          level: { required },
          title: { required },
          university: { required },
          otherUniversity: { required: requiredIf((model) => model.university == '0') },
        },
      },
      workExperiences: {
        required,
        $each: {
          position: { required },
          companyName: { required },
          startYear: { required },
          endYear: { required: requiredIf((model) => model.isCurrentlyWork == false) },
        },
      },
    },
  },
  async mounted() {
    this.loadUniversities()
  },
  methods: {
    parseErrors,
    isInvalidField,
    ...mapActions({
      loadUniversities: 'general/loadUniversities',
    }),
    addSpecialization() {
      if (this.isEmptySpecialization) return
      this.value.specialization.push({
        select: '',
        input: '',
        isOther: this.otherOptionValue,
      })
    },
    removeSpecialization(index) {
      this.value.specialization.splice(index, 1)
    },
    addLanguages() {
      if (this.isEmptyLanguages) return
      this.value.languages.push('')
    },
    removeLanguages(index) {
      this.value.languages.splice(index, 1)
    },
    addClientAgeHandled() {
      if (this.isEmptyClientAgeHandled) return
      this.value.clientAgeHandled.push('')
    },
    removeClientAgeHandled(index) {
      this.value.clientAgeHandled.splice(index, 1)
    },
    addProblemHandled() {
      if (this.isEmptyProblemHandled) return
      this.value.problemHandled.push('')
    },
    removeProblemHandled(index) {
      this.value.problemHandled.splice(index, 1)
    },
    removeWorkExperience(index) {
      this.value.workExperiences.splice(index, 1)
    },
    addWorkExperience() {
      if (this.isEmptyworkExperiences) return
      this.value.workExperiences.push({
        position: null,
        companyName: null,
        endYear: null,
        startYear: null,
        isCurrentlyWork: false,
      })
    },
    onSetOtherUniversity(e, index) {
      this.value.education[index].otherUniversity = e.target.value
    },
    addEducation() {
      if (this.isEmptyEducation) return
      this.value.education.push({
        level: null,
        title: null,
        university: null,
        otherUniversity: null,
      })
    },
    removeEducation(index) {
      this.value.education.splice(index, 1)
      if (this.value.education.length === 0) {
        this.value.education.push({
          level: null,
          title: null,
          university: null,
          otherUniversity: null,
        })
      }
    },
    onSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
<style>
.ui.search.dropdown > input.search,
.ui.selection.dropdown > .dropdown.icon {
  top: auto;
}
.ui.dropdown .menu > .item {
  font-size: 16px;
}
</style>