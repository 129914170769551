var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": true,
      "is-centered": "",
      "size": "2xl",
      "on-close": _vm.close
    }
  }, [_c('c-modal-content', {
    ref: "content",
    staticClass: "modalss",
    attrs: {
      "w": "100%",
      "max-w": _vm.maxWidth
    }
  }, [_c('c-modal-body', {
    attrs: {
      "p": "0",
      "d": "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('CImage', {
    attrs: {
      "src": _vm.image,
      "alt": "success",
      "pos": "relative",
      "z-index": "1",
      "mx": "auto",
      "mb": "20px",
      "h": "150px"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700",
      "font-family": "Roboto",
      "align": "center",
      "color": "#008C81",
      "pb": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.note ? _c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "font-family": "Roboto",
      "align": "center",
      "color": "#555555",
      "pb": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.note) + " ")]) : _vm._e(), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "gap": "20px",
      "w": "100%",
      "mx": "auto",
      "justify-content": "center"
    }
  }, [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          bg: '#008C81'
        },
        ':disabled': {
          opacity: '1 !important'
        }
      },
      expression: "{\n            ':hover': {\n              bg: '#008C81',\n            },\n            ':disabled': {\n              opacity: '1 !important',\n            },\n          }"
    }],
    attrs: {
      "min-width": "130px",
      "w": ['fit-content'],
      "h": "34px",
      "bg": "#008C81",
      "color": "white",
      "border-radius": ['16px', '32px'],
      "px": ['10px', '50px'],
      "py": "7px",
      "font-family": "Roboto",
      "font-size": ['14px'],
      "font-weight": ['500']
    },
    on: {
      "click": _vm.close
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "12px",
      "align-items": "center",
      "justify-content": "center",
      "flex-grow": "1"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "font-weight": ['500'],
      "align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-confirm.svg'),
      "h": '24px',
      "w": '24px'
    }
  })], 1)], 1)], 1)], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }