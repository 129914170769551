<template>
  <c-box>
    <c-box
      v-for="(value, index) in data"
      :key="'problem-handled-' + index"
      position="relative"
    >
      <c-form-control
        mb="12px"
      >
        <c-form-label
          :font-size="isNutri ? ['14px', '16px'] : '14px'"
          :color="(isNutri ? 'primary.400' : '#555')"
          :font-weight="isNutri ? '400' : '500'"
          font-family="Roboto"
        > 
          Masalah yang dikuasai {{ index + 1 }}
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="data[index]"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Masalah yang dikuasai"
            height="62px"
            :font-size="isNutri ? ['16px', '18px'] : '16px'"
            :color="isNutri ? 'primary.400' : 'inherit'"
            :font-weight="isNutri ? '500' : 'inherit'"
            :border-radius="isNutri ? '0.5rem' : '0.25rem'"
            :focus-border-color="isNutri ? 'primary.400' : 'inherit'"
          />
        </c-input-group>
      </c-form-control>
      <c-box
        v-if="data.length > 1"
        mb="13px"
        text-align="right"
      >
        <c-button
          ml="18px"
          min-width="auto"
          variant-color="red"
          variant="link"
          font-size="14px"
          @click="onClickRemove(index)"
        >
          <c-image
            :src="require('@/assets/icon-trash-line.svg')"
            alt="empty"
            mr="4px"
            width="20px"
            height="20px"
          />
          Hapus Masalah yang dikuasai {{ index + 1 }}
        </c-button>
      </c-box>
    </c-box>
    <c-button
      :is-disabled="isEmpty"
      variant-color="primary"
      variant="outline"
      border-radius="60px"
      h="62px"
      w="100%"
      left-icon="add"
      mb="25px"
      mt="12px"
      @click="$emit('add')"
    >
      Tambah Masalah yang dikuasai
    </c-button>

    <ModalConfirm
      :title="`Anda yakin ingin hapus masalah yang dikuasai ${selectedIndex+1}?`"
      :image="require('@/assets/icon-confirm-delete.svg')"
      :is-open="isOpenConfirm"
      :is-loading="false"
      submit-text="Hapus"
      @close="onCloseConfirm"
      @submit="onSubmitConfim"
    />
  </c-box>
</template>

<script>
import ModalConfirm from '@/components/widgets/modal-confirm'

export default {
  name: 'FormProblemHandled',
  components: { ModalConfirm },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [''],
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpenConfirm: false,
      selectedIndex: null,
    }
  },
  computed: {
    isNutri() {
      if (this.type == 'nutri') return true
      return false
    },
  },
  emit: ['add', 'remove'],
  methods: {
    onClickRemove(index) {
      this.selectedIndex = index
      this.isOpenConfirm = true
    },
    onCloseConfirm() {
      this.isOpenConfirm = false
      this.selectedIndex = null
    },
    async onSubmitConfim() {
      await this.$emit('remove', this.selectedIndex)
      this.onCloseConfirm()
    },
  },
}
</script>

<style>

</style>