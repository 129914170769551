<template>
  <c-box>
    <c-box
      v-for="(item, index) in data"
      :key="'specialization-' + index"
      position="relative"
    >
      <c-box
        :color="invalid?.$each[index]?.select?.$invalid ? 'danger.400' : (isNutri ? 'primary.400' : '#555')"
        :font-weight="isNutri ? '400' : '500'"
        font-family="Roboto"
        :p="isNutri ? '0 12px 4px 8px' : '0 12px 4px 0'"
        :font-size="isNutri ? ['14px', '16px'] : '14px'"
      > 
        Spesialisasi {{ index + 1 }}
      </c-box>
      <c-form-control
        mb="12px"
        :is-invalid="invalid?.$each[index]?.select?.$invalid"
      >
        <c-input-group size="md">
          <c-select
            v-model="item.select"
            height="62px"
            placeholder="Pilih Spesialisasi / Keahlian"
            :font-size="isNutri ? ['16px', '18px'] : '16px'"
            :color="isNutri ? 'primary.400' : 'inherit'"
            :font-weight="isNutri ? '500' : 'inherit'"
            :border-radius="isNutri ? '0.5rem' : '0.25rem'"
            :focus-border-color="isNutri ? 'primary.400' : 'inherit'"
          >
            <option
              v-for="item_ in options"
              :key="item_.value"
              :value="item_.value"
              :disabled="isAlreadyChecked(item_.value) && (item_.value != otherValue)"
            >
              {{ item_.label }}
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>

      <c-form-control
        v-if="item.select == otherValue"
        is-required
        mb="12px"
        :is-invalid="invalid?.$each[index]?.input?.$invalid"
      >
        <c-input-group size="md">
          <c-input
            v-model="item.input"
            type="text"
            :font-size="isNutri ? ['16px', '18px'] : '16px'"
            :color="isNutri ? 'primary.400' : 'inherit'"
            :font-weight="isNutri ? '500' : 'inherit'"
            :border-radius="isNutri ? '0.5rem' : '0.25rem'"
            :focus-border-color="isNutri ? 'primary.400' : 'inherit'"
            error-border-color="red.300"
            placeholder="Masukkan Lainnya"
            height="62px"
          />
        </c-input-group>
      </c-form-control>

      <c-box
        v-if="data.length > 1"
        mb="13px"
        text-align="right"
      >
        <c-button
          ml="18px"
          min-width="auto"
          variant-color="red"
          variant="link"
          font-size="14px"
          @click="onClickRemove(index)"
        >
          <c-image
            :src="require('@/assets/icon-trash-line.svg')"
            alt="empty"
            mr="4px"
            width="20px"
            height="20px"
          />
          Hapus Spesialisasi {{ index + 1 }}
        </c-button>
      </c-box>
    </c-box>

    <c-button
      v-if="data.length < 3"
      :is-disabled="isEmpty"
      variant-color="primary"
      variant="outline"
      border-radius="60px"
      h="62px"
      w="100%"
      left-icon="add"
      mb="25px"
      mt="12px"
      @click="$emit('add')"
    >
      Tambah Spesialisasi
    </c-button>

    <ModalConfirm
      :title="`Anda yakin ingin hapus spesialisasi ${selectedIndex+1}?`"
      :image="require('@/assets/icon-confirm-delete.svg')"
      :is-open="isOpenConfirm"
      :is-loading="false"
      submit-text="Hapus"
      @close="onCloseConfirm"
      @submit="onSubmitConfim"
    />
  </c-box>
</template>

<script>
import ModalConfirm from '@/components/widgets/modal-confirm'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'FormSpecialization',
  components: {
    ModalConfirm, 
  },
  mixins: [clickaway],
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [''],
    },
    options: {
      type: Array,
      required: true,
      default: () => [''],
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Object,
      default: () => ({}),
    },
    otherValue: {
      type: String,
      default: 'Lainnya',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpenConfirm: false,
      selectedIndex: null,
      placeholder: 'Pilih Spesialisasi / Keahlian',
    }
  },
  computed: {
    isNutri() {
      if (this.type == 'nutri') return true
      return false
    },
  },
  emit: ['add', 'remove', 'update'],
  methods: {
    isAlreadyChecked(value) {
      if (value == this.otherValue) {
        return false
      }
      if (this.data) {
        return this.data?.some((it) => it.select == value)
      }
      return false
    },
    onClickRemove(index) {
      this.selectedIndex = index
      this.isOpenConfirm = true
    },
    onCloseConfirm() {
      this.isOpenConfirm = false
      this.selectedIndex = null
    },
    async onSubmitConfim() {
      await this.$emit('remove', this.selectedIndex)
      this.onCloseConfirm()
    },
  },
}
</script>

<style>

</style>