<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :margin-bottom="isWithoutMarginBottom ? '0' : ['16px', '24px']"
    :is-read-only="isReadOnly"
    :width="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      v-if="!!label"
      :font-size="['14px', '16px']"
      :color="isInvalid ? 'danger.400' : value ? 'primary.400' : '#555'"
      font-weight="400"
      font-family="Roboto"
      padding-left="8px"
    >
      {{ label }}
      <c-box
        v-if="!isRequired && isRequired !== undefined"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <c-box
      position="relative"
      :height="['48px', '62px']"
    >
      <ModelListSelect
        v-model="modelValue"
        :list="canManualInput ? [
          ...options,
          ...(manualInputValue && !isValueAlreadyExist ? [{
            id: manualInputValue,
            name: `${manualInputValue}`,
          }] : [])
        ] : options"
        option-value="id"
        option-text="name"
        :placeholder="placeholder"
        :is-disabled="isDisabled"
        :class="{
          'base-input-autocomplete': true,
          err: isInvalid,
        }"
        @searchchange="handleManualInput"
        @blur="$emit('blur')"
      />
    </c-box>

    <c-form-helper-text
      v-if="isHelper"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      display="flex"
      gap="8px"
      align-items="center"
      :color="helperTextColor"
    >
      <c-box
        display="flex"
        gap="8px"
        align-items="center"
        :cursor="hasHelperTextClick ? 'pointer' : 'auto'"
        @click="$emit('helper-text-click')"
      >
        <c-box
          v-if="helperTextLogo"
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="helperTextLogo"
            height="100%"
            width="100%"
            :fill="helperTextColor"
          />
        </c-box>
        {{ helperText }}
      </c-box>
    </c-form-helper-text>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      display="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
      >
        &#9679; {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { formatDateV2 } from '@/utils/format-date'
import { isDataEmpty } from '@/utils/is-data-empty'
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

export default {
  name: 'BaseInputAutocomplete',
  components: {
    ModelListSelect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: undefined,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    helperTextType: {
      type: String,
      default: '',
    },
    helperTextColor: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'date',
      validator(value) {
        return [
          'date',
          'datetime',
          'year',
          'month',
          'time',
          'week',
        ].includes(value)
      },
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    valueType: {
      type: String,
      default: 'format',
    },
    canManualInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['blur', 'input', 'trigger-change'],
  data() {
    return {
      manualInputValue: '',
    }
  },
  computed: {
    isValueAlreadyExist() {
      return this.options?.some((option) => {
        // use regex from https://github.com/moreta/vue-search-select/blob/main/libs/components/utils.ts
        return option?.id?.match(new RegExp(this.manualInputValue?.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i'))
      })
    },
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    helperTextLogo() {
      if (this.helperTextType === 'warn') {
        return require('@/assets/icons/icon-circle-warn.svg')
      }
      if (this.helperTextType === 'questionmark') {
        return require('@/assets/icons/icon-circle-questionmark.svg')
      }
      return ''
    },
    hasHelperTextClick() {
      return this.$listeners['helper-text-click']
    },
    modelValue: {
      get() {
        console.log(33333123, 'get', this.value)
        return this.value
      },
      set(val) {
        const isNewValue = this.options?.some((option) => {
          // use regex from https://github.com/moreta/vue-search-select/blob/main/libs/components/utils.ts
          return option?.id?.match(new RegExp(val?.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i'))
        })
        const trimmed = val.trim()
        this.$emit('input', trimmed)
        this.$emit('trigger-change', trimmed, isNewValue)
        this.$emit('blur')
        this.manualInputValue = ''
      },
    },
  },
  methods: {
    isDataEmpty,
    formatDateV2,
    handleManualInput(v) {
      if (v) {
        this.manualInputValue = v
      } else if (this.value) {
        this.manualInputValue = this.value
      }

    },
  },
}
</script>

<style scoped>
::v-deep .base-input-autocomplete {
  font-size: 16px;
  height: 100%;
  width: 100%;
  color: #008C81 !important;
  font-weight: 500;
  border-radius: 8px !important;
}

@media (min-width: 768px) {
  ::v-deep .base-input-autocomplete {
    font-size: 18px;
  }
}

::v-deep .base-input-autocomplete input {
  font-size: 16px;
  height: 100%;
  width: 100%;
  color: #008C81 !important;
  font-weight: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  ::v-deep .base-input-autocomplete input {
    font-size: 18px;
  }
}

::v-deep .base-input-autocomplete div.text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

::v-deep i.dropdown.icon {
  height: 100% !important;
  display: flex;
  align-items: center;
}

::v-deep .err {
  outline: 2px solid #e66673 !important;
  color: #D32737 !important;
}

::v-deep .err:focus-visible {
  outline: 2px solid #e66673 !important;
}
</style>